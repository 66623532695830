import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { LessonAPI } from '../../api/bijles/lessonAPI';
import { RoomAPI } from '../../api/bijles/roomAPI';
import { queryClient } from '../../customQueryclient';
import { Form } from '../../libs/Forms/Form';
import { DateTimeField } from '../../libs/Forms/fields/DateTimeField';
import { Input } from '../../libs/Forms/fields/Input';
import { Select } from '../../libs/Forms/fields/Select';
const schema = z.object({
    id: z.number().optional(),
    name: z.string(),
    // training: z.coerce.number(),
    datetimeStart: z.string(),
    datetimeEnd: z.string(),
    room: z.coerce.number(),
});
const removeFromList = (list, entryId) => {
    return list.filter((entry) => entry.entryId !== entryId);
};
export const LessonFormList = ({ trainingId }) => {
    var _a;
    const lessons = useQuery(LessonAPI.LIST());
    const lessonsForThisTraining = (_a = lessons.data) === null || _a === void 0 ? void 0 : _a.filter((lesson) => lesson.training === trainingId);
    const lessonEntries = lessonsForThisTraining === null || lessonsForThisTraining === void 0 ? void 0 : lessonsForThisTraining.map((lesson) => ({ entryId: lesson.id, lesson }));
    const [lessonList, setLessonList] = useState(lessonEntries !== null && lessonEntries !== void 0 ? lessonEntries : []);
    useEffect(() => {
        setLessonList(lessonEntries !== null && lessonEntries !== void 0 ? lessonEntries : []);
    }, [lessons.data]);
    const removeEntry = (entryId) => {
        setLessonList((prev) => removeFromList(prev, entryId));
    };
    return (_jsxs("div", { className: "p-5 border-black border-2 border-solid", children: [lessonList.map((lessonEntry) => (_jsx(LessonForm, { lesson: lessonEntry.lesson, afterDelete: () => removeEntry(lessonEntry.entryId), trainingId: trainingId }, lessonEntry.entryId))), _jsx("button", { className: "btn btn-primary", onClick: () => setLessonList((prev) => [...prev, { entryId: Date.now() }]), children: "+ Add lesson" })] }));
};
export const LessonForm = ({ lesson, close, trainingId, afterDelete, }) => {
    var _a, _b;
    const [currentId, setCurrentId] = useState(lesson === null || lesson === void 0 ? void 0 : lesson.id);
    const editLesson = useMutation(LessonAPI.PUT);
    const addLesson = useMutation(LessonAPI.POST);
    const deleteLesson = useMutation(LessonAPI.DELETE);
    const rooms = useQuery(RoomAPI.LIST());
    const roomOptions = (_b = (_a = rooms.data) === null || _a === void 0 ? void 0 : _a.map((room) => {
        return {
            label: `${room.name}`,
            value: room.id,
        };
    })) !== null && _b !== void 0 ? _b : [];
    const onDelete = () => {
        if (currentId === undefined) {
            afterDelete();
            return;
        }
        deleteLesson.mutate(currentId, {
            onSuccess: () => {
                afterDelete();
            },
        });
    };
    const create = (lesson) => {
        addLesson.mutate(lesson, {
            onSuccess: (data) => {
                const response = data;
                setCurrentId(response.id);
                queryClient.invalidateQueries({ queryKey: LessonAPI.LIST().queryKey });
                close && close();
            },
        });
    };
    const update = (lesson) => {
        if (currentId === undefined)
            throw Error('On Edit Room should have ID');
        editLesson.mutate(lesson, {
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: LessonAPI.LIST().queryKey });
                close && close();
            },
        });
    };
    const onSubmit = (values) => {
        var _a, _b;
        const usedTrainingId = (_b = (_a = lesson === null || lesson === void 0 ? void 0 : lesson.training) !== null && _a !== void 0 ? _a : lesson === null || lesson === void 0 ? void 0 : lesson.training) !== null && _b !== void 0 ? _b : trainingId;
        if (usedTrainingId === undefined)
            throw Error('Lesson should have trainingId');
        const createValues = Object.assign(Object.assign({}, values), { training: usedTrainingId });
        if (currentId === undefined) {
            create(createValues);
            toast.success('Added a Lesson');
            return;
        }
        const updateValues = Object.assign(Object.assign({}, createValues), { id: currentId });
        if (currentId !== undefined) {
            update(updateValues);
            toast.success('Updated the Lesson');
        }
    };
    return (_jsx(Form, { onSubmit: onSubmit, schema: schema, defaultValues: Object.assign(Object.assign({}, lesson), { datetimeEnd: lesson === null || lesson === void 0 ? void 0 : lesson.datetimeEnd.split('+')[0], datetimeStart: lesson === null || lesson === void 0 ? void 0 : lesson.datetimeStart.split('+')[0] }), children: _jsxs("div", { className: "md:max-w-xl", children: [_jsx("h2", { children: "Lesson" }), _jsx(Input, { name: "name" }), _jsx(Select, { name: "room", options: roomOptions, selectedValue: lesson === null || lesson === void 0 ? void 0 : lesson.room }), _jsx(DateTimeField, { name: "datetimeStart" }), _jsx(DateTimeField, { name: "datetimeEnd" }), _jsx("div", { children: _jsx("input", { type: "submit", className: "btn btn-blue inline-block mt-3 border-0", value: currentId ? 'Update' : 'Create' }) }), _jsx("div", { children: _jsx("div", { className: "btn btn-blue inline-block mt-3 border-0", onClick: onDelete, children: "Delete" }) })] }) }));
};
