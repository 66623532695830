import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useTransactionStatusResult } from '../api/transactionLoader';
import { statusColors } from './subpages/SubscriptionOverview';
const TransactionBase = ({ text, imgUrl }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const transactionStatus = useTransactionStatusResult();
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "grid place-items-center text-center", children: [_jsx("h2", { className: "inline-block mr-3", children: "Transaction:" }), ((_a = transactionStatus.data) === null || _a === void 0 ? void 0 : _a.status) && (_jsx("span", { className: ` px-5 pill ${statusColors[(_b = transactionStatus.data) === null || _b === void 0 ? void 0 : _b.status]}`, children: (_c = transactionStatus.data) === null || _c === void 0 ? void 0 : _c.status })), _jsx("h3", { className: "text-gray-500 my-12", children: t(text) }), imgUrl && _jsx("img", { className: "rounded-md", src: imgUrl, alt: "Spongebob gif representating status" })] }) }));
};
const TransactionPaid = () => {
    const text = 'transaction-paid-description';
    const imgUrl = '/static/spongebob_music_dance.gif';
    return _jsx(TransactionBase, { text: text, imgUrl: imgUrl });
};
const TransactionFailed = () => {
    const text = 'transaction-failed-description';
    return _jsx(TransactionBase, { text: text });
};
const TransactionCanceled = () => {
    const text = 'transaction-canceled-description';
    const imgUrl = '/static/spongebob_crying.gif';
    return _jsx(TransactionBase, { text: text, imgUrl: imgUrl });
};
const TransactionExpired = () => {
    const text = 'transaction-expired-description';
    return _jsx(TransactionBase, { text: text });
};
const TransactionOpen = () => {
    const text = 'transaction-open-description';
    return _jsx(TransactionBase, { text: text });
};
const TransactionPending = () => {
    const text = 'transaction-pending-description';
    return _jsx(TransactionBase, { text: text });
};
const transactionStatusDict = {
    paid: TransactionPaid,
    failed: TransactionFailed,
    canceled: TransactionCanceled,
    expired: TransactionExpired,
    open: TransactionOpen,
    pending: TransactionPending,
};
export const TransactionStatusPage = () => {
    var _a;
    const transactionStatus = useTransactionStatusResult();
    const status = (_a = transactionStatus.data) === null || _a === void 0 ? void 0 : _a.status;
    const TransactionElement = status && transactionStatusDict[status];
    return (_jsx("div", { children: _jsxs("div", { className: "mx-4 grid m-auto", children: [_jsx("div", { className: "max-w-xs" }), _jsx("div", { children: _jsx("div", { className: "max-w-screen-md m-auto", children: _jsx("div", { className: "document", children: TransactionElement && _jsx(TransactionElement, {}) }) }) }), _jsx("div", { className: "max-w-xs" })] }) }));
};
