import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { RoomAPI } from '../../api/bijles/roomAPI';
import { queryClient } from '../../customQueryclient';
import { Form } from '../../libs/Forms/Form';
import { Input } from '../../libs/Forms/fields/Input';
const schema = z.object({
    name: z.string().min(1).max(200),
});
export const RoomForm = ({ room, type, close }) => {
    const editRoom = useMutation(RoomAPI.PUT);
    const addRoom = useMutation(RoomAPI.POST);
    const typeToName = {
        CREATE: 'Create',
        EDIT: 'Edit',
    };
    const onSubmit = (values) => {
        if (type === 'CREATE') {
            addRoom.mutate(values, {
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: RoomAPI.LIST().queryKey });
                    close();
                },
            });
            toast.success('Created a new Room');
        }
        if (type === 'EDIT') {
            if ((room === null || room === void 0 ? void 0 : room.id) === undefined)
                throw Error('On Edit Room should have ID');
            editRoom.mutate(Object.assign(Object.assign({}, values), { id: room.id }), {
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: RoomAPI.LIST().queryKey });
                    close();
                },
            });
            toast.success('Updated the Room');
        }
    };
    return (_jsx(_Fragment, { children: _jsx(Form, { onSubmit: onSubmit, schema: schema, defaultValues: room, children: _jsxs("div", { className: "md:max-w-xl", children: [_jsxs("h1", { children: [typeToName[type], " Room"] }), _jsx(Input, { name: "name" }), _jsx("div", { children: _jsx("input", { type: "submit", className: "btn btn-blue inline-block mt-3 border-0" }) })] }) }) }));
};
