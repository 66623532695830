import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useQueryAccountInfo } from '../../api/studentwiki';
export const SubscriptionOverviewButton = ({ setTab, tab }) => {
    const { t } = useTranslation();
    const thisTab = 'subscription';
    const thisTabIsActive = thisTab === tab;
    const onClick = () => {
        setTab('subscription');
    };
    return (_jsx("div", { className: `m-6 cursor-pointer hover:opacity-100 ${thisTabIsActive ? 'opacity-100' : 'opacity-70'}`, onClick: onClick, children: _jsx("span", { className: `pl-2 text-primary text-4xl font-black border-solid border-0 border-l-4 hover:border-l-secondary border-transparent ${thisTabIsActive ? 'border-l-secondary' : ''}`, children: t('account-page-subscription-tab-name') }) }));
};
export const SubscriptionOverview = () => {
    var _a, _b;
    const { t } = useTranslation();
    const accountInfo = useQueryAccountInfo();
    return (_jsxs("div", { className: "text-center pb-3", children: [_jsx("h1", { className: "text-primary text-xl font-black text-center", children: t('account-page-subscription-tab-name') }), _jsxs("p", { children: [t('account-page-has-active-subscription-label'), ":", ' ', _jsx("span", { children: ((_a = accountInfo.data) === null || _a === void 0 ? void 0 : _a.subscription) ? 'Yes' : 'No' })] }), _jsx("div", { className: "flex items-center justify-center", children: accountInfo.data && _jsx(TransactionTable, { transactions: (_b = accountInfo.data) === null || _b === void 0 ? void 0 : _b.transactions }) })] }));
};
const TransactionTable = ({ transactions }) => {
    // <p>No transcations have been made with this account</p>
    const Transactions = () => (_jsx(_Fragment, { children: transactions.map((transaction, i) => {
            return _jsx(TransactionEntry, { transaction: transaction }, i);
        }) }));
    return (_jsx("table", { className: "table-auto border-spacing-2", children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsx("th", { children: "Status" }), _jsx("th", { children: "Amount" }), _jsx("th", { children: "Method" }), _jsx("th", { children: "Paid at" }), _jsx("th", { children: "Other" })] }), _jsx(Transactions, {})] }) }));
};
export const statusColors = {
    paid: 'bg-green-400',
    open: 'bg-blue-300',
    canceled: 'bg-red-400',
    pending: 'bg-blue-300',
    authorized: 'bg-green-300',
    expired: 'bg-black',
    failed: 'bg-red-400',
};
const TransactionEntry = ({ transaction }) => {
    const getFormattedDate = () => {
        if (!transaction.mollie_paid_at)
            return null;
        const datePayedAt = new Date(transaction.mollie_paid_at);
        const order = [datePayedAt.getDate(), datePayedAt.getMonth(), datePayedAt.getFullYear()];
        return order.join('-');
    };
    const getFormattedAmount = () => {
        if (!transaction.amount)
            return null;
        return transaction.amount + ' €';
    };
    const getStatusColor = () => {
        return statusColors[transaction.status];
    };
    return (_jsxs("tr", { children: [_jsx("td", { children: _jsx("span", { className: 'pill ' + getStatusColor(), children: transaction.status }) }), _jsxs("td", { children: [getFormattedAmount(), " "] }), _jsx("td", { children: transaction.method }), _jsx("td", { children: getFormattedDate() }), _jsxs("td", { children: [transaction.amount_chargeback && (_jsxs("span", { className: "pill bg-red-500", children: ["Chargeback: ", transaction.amount_chargeback, " \u20AC"] })), transaction.amount_refuneded && transaction.amount_refuneded != '0.00' && (_jsxs("span", { className: "pill bg-blue-300", children: ["Refunded: ", transaction.amount_refuneded, " \u20AC"] }))] })] }));
};
