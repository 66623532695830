import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { TrainerAPI } from '../../api/bijles/trainerAPI';
import { TrainingAPI, toOptions } from '../../api/bijles/trainingsAPI';
import { useQueryCourse } from '../../api/studentwiki';
import { Form } from '../../libs/Forms/Form';
import { Input } from '../../libs/Forms/fields/Input';
import { Select } from '../../libs/Forms/fields/Select';
import { TextArea } from '../../libs/Forms/fields/TextArea';
import { LessonFormList } from './LessonForm';
const schema = z.object({
    name: z.string().min(5).max(200),
    description: z.string().min(20).max(5000),
    trainer: z.coerce.number(),
    seats: z.coerce.number(),
    price: z.coerce.number(),
    email_language: z.string(),
    course: z.coerce.number(),
});
export const TrainingForm = ({ training, type, close, }) => {
    const resourceFormId = type === 'CREATE' ? undefined : training === null || training === void 0 ? void 0 : training.id;
    const [currentId, setCurrentId] = useState(resourceFormId);
    const editTraining = useMutation(TrainingAPI.PUT);
    const addTraining = useMutation(TrainingAPI.POST);
    const listTrainer = useQuery(TrainerAPI.LIST());
    const courses = useQueryCourse();
    const typeToName = currentId === undefined ? 'Create' : 'Edit';
    const coursesOptions = toOptions(courses.data, (course) => `${course.name} (${course.study})`, (course) => course.id);
    const trainerOptions = toOptions(listTrainer.data, (trainger) => `${trainger.first_name} ${trainger.last_name}`, (trainger) => trainger.id);
    const onSubmit = (values) => {
        if (currentId === undefined) {
            addTraining.mutate(values, {
                onSuccess: (data) => {
                    const response = data;
                    setCurrentId(response.id);
                },
            });
            toast.success('Created a new training');
        }
        if (currentId !== undefined) {
            editTraining.mutate(Object.assign(Object.assign({}, values), { id: currentId }), {
                onSuccess: () => {
                    // queryClient.invalidateQueries({ queryKey: TrainingAPI.LIST().queryKey });
                },
            });
            toast.success('Updated the training');
        }
    };
    const languageOptions = [
        { value: 'nl', label: 'Nederlands' },
        { value: 'en', label: 'English' },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(Form, { onSubmit: onSubmit, defaultValues: training, schema: schema, children: _jsxs("div", { className: "md:max-w-xl", children: [_jsxs("h1", { children: [typeToName, " Training"] }), _jsx(Input, { name: "name" }), _jsx(TextArea, { name: "description" }), _jsx(Input, { name: "seats" }), _jsx(Select, { name: "email_language", options: languageOptions, selectedValue: training === null || training === void 0 ? void 0 : training.email_language }), _jsx(Input, { name: "price" }), _jsx(Select, { name: "course", options: coursesOptions, selectedValue: training === null || training === void 0 ? void 0 : training.course }), _jsx(Select, { name: "trainer", options: trainerOptions, selectedValue: training === null || training === void 0 ? void 0 : training.trainer }), _jsx("div", { children: _jsx("input", { type: "submit", className: "btn btn-blue inline-block mt-3 border-0", value: currentId ? 'Update' : 'Create' }) })] }) }), currentId && _jsx(LessonFormList, { trainingId: currentId })] }));
};
