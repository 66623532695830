import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useGoogleLogin as useGoogleLogin2 } from '@react-oauth/google';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getCsrfCookieValue } from '../api/getCsrfFromCookie';
import { useLogoutMutation } from '../api/mutations';
import { useUserStore } from './atoms/userAtom';
export const LoginButtonGoogle = ({ onSuccess }) => {
    const userStore = useUserStore();
    const { t } = useTranslation();
    const onSuccessRequest = (googleLoginResponse) => {
        onSuccess && onSuccess();
        const data = {
            access_token: googleLoginResponse.access_token,
        };
        const loginUrl = '/user/api/v1/rest-auth/google_auth/';
        fetch(loginUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfCookieValue(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
            const userDetails = {
                loggedIn: true,
                user: {
                    first_name: data.user.first_name,
                    last_name: data.user.last_name,
                    id: data.user.pk,
                },
            };
            userStore.setUser(userDetails);
            toast.success(t('logged-in-succesfully'));
            ReactGA.event('login', { method: 'Google', user_id: data.user.pk });
        });
    };
    const onError = (error) => {
        // TODO: log error
        error;
        alert('Something went wrong while logging in, please try again and contact us if the issues persists');
    };
    const loginGoogle = useGoogleLogin2({ onSuccess: onSuccessRequest, onError: onError });
    return (_jsx("div", { className: "btn-google rounded-full", onClick: () => loginGoogle(), children: _jsxs("div", { className: "google-content", children: [_jsx("div", { className: "logo", children: _jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 48 48", children: [_jsx("defs", { children: _jsx("path", { id: "a", d: "M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z" }) }), _jsx("clipPath", { id: "b", children: _jsx("use", { href: "#a", overflow: "visible" }) }), _jsx("path", { clipPath: "url(#b)", fill: "#FBBC05", d: "M0 37V11l17 13z" }), _jsx("path", { clipPath: "url(#b)", fill: "#EA4335", d: "M0 11l17 13 7-6.1L48 14V0H0z" }), _jsx("path", { clipPath: "url(#b)", fill: "#34A853", d: "M0 37l30-23 7.9 1L48 0v48H0z" }), _jsx("path", { clipPath: "url(#b)", fill: "#4285F4", d: "M48 48L17 24l-4-3 35-10z" })] }) }), _jsx("p", { children: "Sign in with Google" })] }) }));
};
export function LogoutButton() {
    const { t } = useTranslation();
    const loggedIn = useUserStore((state) => state.loggedIn);
    const logoutMutation = useLogoutMutation();
    if (!loggedIn)
        return _jsx(_Fragment, {});
    return (_jsx("button", { onClick: () => logoutMutation.mutate(), className: "btn btn-blue float-right", children: t('sign-out') }));
}
