import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BijlesRoutes, CONTACT_EMAIL, KVK } from '../../App/constants';
export const Footer = ({ textColor = 'text-white' }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (_jsxs("footer", { className: ' bg-slate-700 py-10 ' + textColor, children: [_jsxs("section", { className: "p-5 grid grid-cols-1 md:grid-cols-3 max-w-4xl m-auto", children: [_jsxs("div", { children: [_jsx("h5", { className: " text-white", children: "Bedrijf" }), _jsx("span", { className: "block cursor-pointer", onClick: () => navigate(BijlesRoutes.PS), children: "Privacybeleid" }), _jsx("span", { className: "block cursor-pointer", onClick: () => navigate(BijlesRoutes.TAC), children: "Algemene voorwaarden" })] }), _jsxs("div", { children: [_jsx("h5", { className: "text-white", children: "Contact" }), _jsxs("span", { className: "block", children: [_jsx(MailOutlineIcon, { className: "align-middle" }), _jsx("span", { className: "pl-2 align-middle", children: CONTACT_EMAIL })] })] })] }), _jsx("div", { className: "text-sm p-2 grid place-items-center", children: _jsx("div", { children: t('copyright-statement-part-1') + KVK }) })] }));
};
