import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { default as en } from './en.json';
import { default as nl } from './nl.json';
// Export is needed to provide typing to the .d.ts file of react-i18next
const translationResources = {
    nl: {
        translation: nl,
    },
    en: {
        translation: en,
    },
};
i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
    resources: translationResources,
    lng: 'nl',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
});
export { i18n };
