import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BijlesRoutes } from '../App/constants';
import { useUserStore } from '../authentication/atoms/userAtom';
import { getCsrfCookieValue } from './getCsrfFromCookie';
export const setUserStudy = (studyId) => {
    return fetch('/user/drfviews/profile/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': getCsrfCookieValue(),
        },
        body: JSON.stringify({ studyId }),
    });
};
const signOut = () => {
    const logoutUrl = '/auth/logout/';
    return fetch(logoutUrl, {
        method: 'POST',
        headers: {
            'X-CSRFToken': getCsrfCookieValue(),
        },
    });
};
export const useLogoutMutation = () => {
    const { t } = useTranslation();
    const clearUser = useUserStore((state) => state.clear);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const logoutMutation = useMutation(signOut, {
        onSuccess: () => {
            clearUser();
            queryClient.invalidateQueries();
            // Only invalidating queries is not enough. As stale queries will be refetched in
            // the background if currently rendered. So is not rendered and you go to a page
            // where the query is used it shows the old data for a split second. Hence remove all
            queryClient.removeQueries();
            navigate(BijlesRoutes.HOME_PAGE);
            toast.success(t('logged-out-succesfully'));
        },
    });
    return logoutMutation;
};
