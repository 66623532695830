import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../formUtils';
export const Select = ({ options, name, label, selectedValue, }) => {
    var _a;
    const methods = useFormContext();
    const [valueIsSet, setValueIsSet] = useState(false);
    useEffect(() => {
        if (selectedValue && options.length !== 0 && !valueIsSet) {
            methods.setValue(name, selectedValue);
            setValueIsSet(true);
        }
    }, [selectedValue, options]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "py-2", children: label !== null && label !== void 0 ? label : name }), _jsxs("select", Object.assign({}, methods.register(name), { className: "w-full p-2 box-border text-base border-black border-2 border-solid", defaultValue: '-1', children: [_jsxs("option", { value: "-1", disabled: true, children: ["Select a ", label !== null && label !== void 0 ? label : name] }), options.map(({ value, label }) => (_jsx("option", { value: value, children: label }, value)))] })), _jsx(ErrorMessage, { errorText: (_a = methods.formState.errors[name]) === null || _a === void 0 ? void 0 : _a.message })] }));
};
