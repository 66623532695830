import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { BijlesRoutes } from '../../App/constants';
import { AttendeeAPI } from '../../api/bijles/attendeeAPI';
import { queryClient } from '../../customQueryclient';
import { Form } from '../../libs/Forms/Form';
import { Input } from '../../libs/Forms/fields/Input';
import { TextArea } from '../../libs/Forms/fields/TextArea';
const schema = z.object({
    first_name: z.string().min(2).max(100),
    last_name: z.string().min(2).max(100),
    email: z.string().email(),
    phone_number: z.string().min(8),
    notes: z.string(),
});
export const AttendeeForm = ({ close, training }) => {
    const addAttendee = useMutation(AttendeeAPI.POST);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const onSubmit = (values) => {
        addAttendee.mutate(Object.assign(Object.assign({}, values), { training: training.id }), {
            onSuccess: (data) => {
                queryClient.invalidateQueries({ queryKey: AttendeeAPI.LIST().queryKey });
                if (data === null || data === void 0 ? void 0 : data.redirect) {
                    window.location.href = data.redirect;
                }
                else if (data === null || data === void 0 ? void 0 : data.successful) {
                    navigate(BijlesRoutes.THANKS_FOR_ORDERING_PAGE_FREE);
                }
                // TODO: loading spinner while waiting to redirect
            },
        });
    };
    return (_jsx(_Fragment, { children: _jsxs(Form, { onSubmit: onSubmit, schema: schema, children: [_jsxs("div", { className: "md:max-w-xl", children: [_jsx("h1", { children: "Inschrijven voor training:" }), _jsx("h2", { children: training.name }), _jsx(Input, { name: "first_name", label: t('field-label-first-name') }), _jsx(Input, { name: "last_name", label: t('field-label-last-name') }), _jsx(Input, { name: "email", label: t('field-label-email') }), _jsx(Input, { name: "phone_number", label: t('field-label-phone-number') }), _jsx(TextArea, { name: "notes", label: t('field-label-notes') })] }), _jsx("div", { children: _jsx("input", { type: "submit", className: "btn btn-blue inline-block mt-3 border-0" }) })] }) }));
};
