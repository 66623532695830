var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useQuery } from 'react-query';
import { useUserStore } from '../authentication/atoms/userAtom';
import { loginModal } from '../views/modals/LoginModal';
import { getCsrfCookieValue } from './getCsrfFromCookie';
const errorCodes = (key) => {
    switch (key) {
        case 'not_authenticated':
            loginModal.show();
            useUserStore.getState().clear();
            throw Error('Please login to access this page');
        default:
            throw Error('Unexpected error: ' + key);
    }
};
export const handleFetchError = (response, data) => {
    const errorCode = data === null || data === void 0 ? void 0 : data.code;
    if (errorCode)
        errorCodes(errorCode);
    if (response.status === 500)
        throw Error('Internal server error');
};
export function customFetch(url, params) {
    var _a;
    const method = (_a = params === null || params === void 0 ? void 0 : params.method) !== null && _a !== void 0 ? _a : 'GET';
    const headers = params === null || params === void 0 ? void 0 : params.headers;
    const getHeaders = () => {
        const defaultHeaders = {};
        if (!headers) {
            defaultHeaders['Content-Type'] = 'application/json';
        }
        if (['POST', 'PUT', 'DELETE'].includes(method) && url[0] === '/') {
            defaultHeaders['X-CSRFToken'] = getCsrfCookieValue();
        }
        return Object.assign(Object.assign({}, defaultHeaders), headers);
    };
    return fetch(url, {
        method,
        headers: getHeaders(),
        body: params === null || params === void 0 ? void 0 : params.body,
    }).then((response) => __awaiter(this, void 0, void 0, function* () {
        let data = {};
        try {
            data = yield response.json();
        }
        catch (error) {
            console.log(error);
        }
        if (!response.ok)
            yield handleFetchError(response, data);
        return data;
    }));
}
export const useQueryInstitution = () => {
    return useQuery(['studies'], () => customFetch('/studies/api/institution/all/'));
};
export const useQueryStudy = () => {
    return useQuery(['studies'], () => customFetch('/studies/api/studies/all/'));
};
export const useQueryCourse = () => {
    return useQuery(['courses'], () => customFetch('/studies/api/courses/all/'));
};
export const useQueryFollowedCourses = () => {
    return useQuery('followed_courses', () => fetch('/user/drfviews/followed_courses/').then((response) => response.json()));
};
export const useFlashCards = (courseId) => {
    return useQuery(['flashcards', courseId], () => customFetch(`/flipcards/api/course/${courseId}/`));
};
export const useQueryFollowCourses = (coursesToFollow) => {
    const query = useQuery('followed_courses', () => customFetch('/user/drfviews/follow/', {
        method: 'POST',
        body: JSON.stringify({ course_ids: coursesToFollow }),
    }), {
        enabled: false,
    });
    return query;
};
export const useQueryAccountInfo = () => {
    return useQuery(['AccountInfo'], () => customFetch('/user/drfviews/account/'));
};
