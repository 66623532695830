import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import Select from 'react-select';
import { setUserStudy } from '../../api/mutations';
import { useQueryAccountInfo, useQueryStudy } from '../../api/studentwiki';
import { useUserStore } from '../../authentication/atoms/userAtom';
import { queryClient } from '../../customQueryclient';
import { studyToOptions } from '../homepage';
export const AccountOverviewButton = ({ setTab, tab }) => {
    const thisTab = 'account';
    const thisTabIsActive = thisTab === tab;
    const onClick = () => {
        setTab('account');
    };
    return (_jsx("div", { className: `m-6 cursor-pointer hover:opacity-100 ${thisTabIsActive ? 'opacity-100' : 'opacity-70'}`, onClick: onClick, children: _jsx("span", { className: `pl-2 text-primary text-4xl font-black border-solid border-0 border-l-4 hover:border-l-secondary border-transparent ${thisTabIsActive ? 'border-l-secondary border-l-4' : ''}`, children: "Account" }) }));
};
export const AccountOverview = () => {
    var _a, _b, _c;
    const accountInfo = useQueryAccountInfo();
    const user = useUserStore((state) => state.user);
    const { t } = useTranslation();
    const userStudyMutation = useMutation(setUserStudy, {
        onSuccess: () => {
            if (selectedOption)
                queryClient.setQueryData(['AccountInfo'], Object.assign(Object.assign({}, accountInfo.data), { user_study: {
                        id: +selectedOption.value,
                        name: selectedOption.label,
                    } }));
        },
    });
    const [selectedOption, setSelectedOption] = useState(null);
    const studyQuery = useQueryStudy();
    const studies = studyQuery.data;
    const studyOptions = (_a = studyToOptions(studies)) !== null && _a !== void 0 ? _a : [];
    const onClick = () => {
        if (selectedOption && !userStudyMutation.isLoading)
            userStudyMutation.mutate(+selectedOption.value);
    };
    const btnLoadingStyle = userStudyMutation.isLoading ? 'btn-loading' : '';
    return (_jsxs("div", { children: [_jsxs("h1", { className: "text-primary text-4xl font-black", children: [user === null || user === void 0 ? void 0 : user.first_name, " ", user === null || user === void 0 ? void 0 : user.last_name] }), _jsxs("p", { children: [_jsx("span", { className: "font-bold text-primary", children: "Email:" }), " ", _jsx("i", { children: (_b = accountInfo.data) === null || _b === void 0 ? void 0 : _b.user_email })] }), _jsxs("p", { children: [_jsxs("span", { className: "font-bold text-primary", children: [t('account-page-current-study-label'), ":"] }), ' ', _jsx("i", { children: (_c = accountInfo.data) === null || _c === void 0 ? void 0 : _c.user_study.name })] }), _jsxs("div", { className: "w-full flex", children: [_jsx(Select, { defaultValue: selectedOption, onChange: setSelectedOption, options: studyOptions, className: 'flex-1', classNamePrefix: "sw-select-rounded-left" }), _jsx("span", { className: 'btn btn-blue inline-block rounded-none rounded-r-full' + btnLoadingStyle, onClick: onClick, children: t('account-page-sumbit-default-study') })] })] }));
};
