import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { RoomAPI } from '../api/bijles/roomAPI';
import { queryClient } from '../customQueryclient';
import { ButtonTriggerModal } from '../libs/BaseComponents/ButtonTriggerModal';
import { InlineModal } from '../libs/Modal/InlineModal';
import { BasicTable } from '../libs/Table/BasicTable';
import { DeleteResourceForm } from './TrainingPage';
import { RoomForm } from './forms/RoomForm';
const columnHelper = createColumnHelper();
const getRoomActionModal = (room, type) => {
    return (close) => _jsx(RoomForm, { room: room, type: type, close: close });
};
const roomColumns = [
    columnHelper.accessor('id', {}),
    columnHelper.accessor('name', {}),
    columnHelper.accessor(() => '', {
        header: 'Actions',
        cell: ({ row }) => {
            return (_jsxs(_Fragment, { children: [_jsx(ButtonTriggerModal, { label: "Edit", getModal: getRoomActionModal(row.original, 'EDIT'), icon: _jsx(EditIcon, {}) }), _jsx(ButtonTriggerModal, { label: "Copy", getModal: getRoomActionModal(row.original, 'CREATE'), icon: _jsx(ContentCopyIcon, {}) }), _jsx(ButtonTriggerModal, { label: "Delete", getModal: getRoomDeleteActionForm(row.original), icon: _jsx(DeleteForeverIcon, {}) })] }));
        },
    }),
];
const getRoomDeleteActionForm = (room) => {
    const roomDelete = useMutation(RoomAPI.DELETE);
    return (close) => {
        const invalidateTraining = () => {
            roomDelete.mutate(room.id, {
                onSuccess: () => {
                    queryClient.invalidateQueries(RoomAPI.LIST());
                    close();
                },
            });
        };
        return _jsx(DeleteResourceForm, { onClick: invalidateTraining, label: "Training", close: close });
    };
};
export const LocationPage = () => {
    const roomList = useQuery(RoomAPI.LIST());
    const [showModal, setShowModal] = useState(false);
    if (!roomList.data)
        return _jsx(_Fragment, { children: "Loading..." });
    return (_jsx("div", { children: _jsxs("div", { className: " max-w-screen-xl p-4 rounded-lg bg-slate-50 m-auto", children: [_jsx("button", { onClick: () => {
                        setShowModal(true);
                    }, className: "btn btn-blue", children: "Add +" }), showModal && (_jsx(InlineModal, { Content: () => _jsx(RoomForm, { type: "CREATE", close: () => setShowModal(false) }), onClose: () => setShowModal(false) })), _jsx(BasicTable, { data: roomList.data, columns: roomColumns })] }) }));
};
