export const BijlesRoutes = {
    USER_PAGE: '/user/',
    ACCOUNT_PAGE: '/account/',
    ATTENDEE_PAGE: '/attendees/:trainingId/',
    LESSON_PAGE: '/lessons/:trainingId/',
    HOME_PAGE: '/',
    ABOUT_US_PAGE: '/about_us/',
    PARTNERS_PAGE: '/partners/',
    PRIVACY_PAGE: '/privacy/',
    TERMS_AND_CONDITIONS_PAGE: '/tac/',
    PAYMENT_FINISHED_PAGE: '/payments/payed/:transactionId/',
    LOCATION_PAGE: '/location/',
    CONTACT: '/contact/',
    // Bijles Routes:
    COURSE_PAGE: '/course/:courseId/',
    ADMIN_OVERVIEW_PAGE: '/teachers/',
    TRAINING_PAGE: '/trainingen/',
    TRAINING_ARCHIVED_PAGE: '/trainingen/archived/',
    ORDERS_PAGE: '/orders/',
    // LESSONS_PAGE: '/lessons/',
    TAC: '/tac/',
    PS: '/privacy_statement/',
    HOMEPAGE2: '/alternative/',
    STUDIES: '/studies/',
    INSTITUTIONS: '/universities/',
    COURSES: '/courses/',
    THANKS_FOR_ORDERING_PAGE_TRANSACTION: '/payments/thankyou/:transactionId/',
    THANKS_FOR_ORDERING_PAGE_FREE: '/payments/thankyou/',
};
export const UNIVERITY_IMAGE = 'https://images.pexels.com/photos/159775/library-la-trobe-study-students-159775.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';
export const TEACHING_IMAGE_1 = 'https://images.pexels.com/photos/5940831/pexels-photo-5940831.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';
export const TEACHING_IMAGE_2 = 'https://images.pexels.com/photos/15141537/pexels-photo-15141537/free-photo-of-man-staand-bedrijf-vergadering.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';
export const TEACHING_IMAGE_3 = 'https://images.pexels.com/photos/3184291/pexels-photo-3184291.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';
export const KVK = '91074096';
export const CONTACT_EMAIL = 'info@tututor.nl';
