import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { setBodyBackgroundColorToPrimary } from '../App';
import { UNIVERITY_IMAGE } from '../App/constants';
import { useQueryCourse } from '../api/studentwiki';
import { groupBy } from './coursepage';
import { Footer } from './subpages/Footer';
export const studyToOptions = (studies) => {
    if (studies === undefined)
        return undefined;
    return studies.map((study) => ({
        value: study.id.toString(),
        label: `${study.name} (${study.institution})`,
    }));
};
function filterArrayUniqueByValue(array, getKey) {
    const seen = new Set();
    return array.filter((item) => {
        const key = getKey(item);
        if (seen.has(key))
            return false;
        seen.add(key);
        return true;
    });
}
export const courseToFullOptions = (courses, studyKey, institutionKey) => {
    if (courses === undefined)
        return undefined;
    // if institution or study is picked, only use studies with that institution
    const targetedCourses = courses.filter((course) => {
        if (studyKey === undefined && institutionKey === undefined)
            return true;
        if (studyKey !== undefined)
            return course.study_id === studyKey;
        return course.institution_id === institutionKey;
    });
    const coursesGroupedByInstitution = groupBy(targetedCourses, (course) => {
        return course.institution;
    });
    let optionsArray = [];
    coursesGroupedByInstitution.forEach((value, key) => {
        const optionsEntry = {
            institution: key,
            value: 0,
            label: key,
            options: sortOptionsAlphabetical(value.map((course) => {
                return {
                    label: course.name,
                    value: course.id,
                    study: course.study,
                    institution: key,
                };
            })),
        };
        optionsArray = optionsArray.concat(optionsEntry);
    });
    return optionsArray;
};
export const studyToFullOptions = (courses, key) => {
    if (courses === undefined)
        return undefined;
    // if institution is picked, only use studies with that institution
    const targetedCourses = courses.filter((course) => {
        if (key === undefined)
            return true;
        return key === course.institution_id;
    });
    const coursesGroupedByInstitution = groupBy(targetedCourses, (course) => {
        return course.institution;
    });
    let optionsArray = [];
    coursesGroupedByInstitution.forEach((value, key) => {
        const optionsEntry = {
            institution: key,
            value: 0,
            label: key,
            options: sortOptionsAlphabetical(value.map((course) => {
                return {
                    label: course.study,
                    value: course.study_id,
                    institution: key,
                };
            })),
        };
        optionsArray = optionsArray.concat(optionsEntry);
    });
    return optionsArray.map((institutionOption) => {
        var _a;
        return Object.assign(Object.assign({}, institutionOption), { options: filterArrayUniqueByValue((_a = institutionOption.options) !== null && _a !== void 0 ? _a : [], (item) => item.value) });
    });
};
export const institutionToFullOptions = (courses) => {
    if (courses === undefined)
        return undefined;
    const institutions = courses.map((value) => {
        return {
            label: value.institution,
            value: value.institution_id,
        };
    });
    return filterArrayUniqueByValue(institutions, (item) => item.value);
};
export const sortOptionsAlphabetical = (options) => {
    return options.sort((a, b) => a.label.localeCompare(b.label));
};
export const Homepage = () => {
    setBodyBackgroundColorToPrimary();
    const { t } = useTranslation();
    const [selectedCourse, setSelectedCourse] = useState();
    const navigate = useNavigate();
    const onClick = () => {
        if (selectedCourse === undefined)
            return undefined;
        const nextPath = generatePath('/course/:id/', {
            id: selectedCourse.toString(),
        });
        navigate(nextPath);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: " m-4 relative", children: [_jsx("div", { className: "absolute h-full w-full overflow-hidden rounded-lg", children: _jsx("img", { className: " object-fill opacity-10 object-center grayscale rounded-lg absolute -z-1", src: UNIVERITY_IMAGE }) }), _jsxs("div", { className: "flex items-center p-14 gap-5 flex-col md:flex-row md:gap-12 xl:gap-32  z-1 relative", children: [_jsxs("div", { className: "text-center z-1 relative", children: [_jsx("h1", { className: "logo-xl text-5xl  sm:text-7xl md:text-8xl lg:text-9xl", children: t('company-name') }), _jsx("p", { children: "Samen leren, samen studeren. Samen komen we er." })] }), _jsx(SelectCourse, { calledOnCourseChange: setSelectedCourse }), _jsx("div", { className: `btn btn-blue text-center flex-2 mt-5 ${selectedCourse ? '' : 'hover:cursor-not-allowed'}`, onClick: onClick, children: t('go-to-course') })] })] }), _jsx(Footer, {})] }));
};
export const SelectCourse = ({ calledOnCourseChange, courseId, }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const courses = useQueryCourse();
    const [selectedInstitution, setSelectedInstitution] = useState(null);
    const [selectedStudy, setSelectedStudy] = useState(null);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const universityOptions = sortOptionsAlphabetical(courses.isError ? [] : (_a = institutionToFullOptions(courses.data)) !== null && _a !== void 0 ? _a : []);
    const studyOptions = sortOptionsAlphabetical(courses.isError ? [] : (_b = studyToFullOptions(courses.data, selectedInstitution === null || selectedInstitution === void 0 ? void 0 : selectedInstitution.value)) !== null && _b !== void 0 ? _b : []);
    const coursesOptions = sortOptionsAlphabetical(courses.isError
        ? []
        : (_c = courseToFullOptions(courses.data, selectedStudy === null || selectedStudy === void 0 ? void 0 : selectedStudy.value, selectedInstitution === null || selectedInstitution === void 0 ? void 0 : selectedInstitution.value)) !== null && _c !== void 0 ? _c : []);
    useEffect(() => {
        var _a;
        if (courseId) {
            const activeCourse = (_a = courses.data) === null || _a === void 0 ? void 0 : _a.find((c) => c.id === +courseId);
            if (!activeCourse)
                return;
            setSelectedInstitution({
                label: activeCourse.institution,
                value: activeCourse.institution_id,
            });
            setSelectedStudy({
                label: activeCourse.study,
                value: activeCourse.study_id,
            });
            setSelectedCourse({
                label: activeCourse.name,
                value: activeCourse.id,
            });
        }
    }, [courses.data]);
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "w-10/12 m-auto flex flex-wrap gap-5 max-w-md", children: [_jsx("div", { className: "flex items-center w-full z-1", children: _jsx(Select, { placeholder: `${t('select-placeholder-institution')}...`, value: selectedInstitution, onChange: (newValue) => {
                            setSelectedInstitution(newValue);
                            setSelectedStudy(null);
                            setSelectedCourse(null);
                        }, options: universityOptions, className: "flex-1", classNamePrefix: "sw-select" }) }), _jsx("div", { className: "flex items-center w-full z-2", children: _jsx(Select, { placeholder: `${t('select-placeholder-study')}...`, value: selectedStudy, onChange: (newValue) => {
                            setSelectedStudy(newValue);
                            setSelectedCourse(null);
                        }, options: studyOptions, className: "flex-1", classNamePrefix: "sw-select" }) }), _jsx("div", { className: "flex items-center w-full z-3", children: _jsx(Select, { placeholder: `${t('select-placeholder-course')}...`, value: selectedCourse, onChange: (newValue) => {
                            setSelectedCourse(newValue);
                            calledOnCourseChange && calledOnCourseChange(newValue === null || newValue === void 0 ? void 0 : newValue.value);
                        }, options: coursesOptions, className: "flex-1", classNamePrefix: "sw-select" }) })] }) }));
};
