import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { createBrowserRouter, Outlet } from 'react-router-dom';
import { bijlesCoursePageInfoloader } from './api/bijles/coursePageAPI';
import { tranasctionStatusloader } from './api/transactionLoader';
import { BijlesRoutes } from './App/constants';
import { queryClient } from './customQueryclient';
import { AccountPage } from './views/account';
import { AdminOverViewPage } from './views/adminOverviewPage';
import { AttendeePage } from './views/AttendeePage';
import { ContactPage } from './views/contactPage';
import { CoursePage } from './views/coursepage';
import { Homepage } from './views/homepage';
import { Homepage2 } from './views/homepage2';
import { LessonPage } from './views/LessonsPage/LessonsPage';
import { LocationPage } from './views/LocationPage';
import { loginModal } from './views/modals/LoginModal';
import { paymentModal } from './views/modals/PaymentModal';
import Navbar from './views/navbar';
import { OrderPage } from './views/OrderPage';
import { StudyPage } from './views/StudyPage';
import { PrivacyVerklaring, TAC } from './views/termsAndConditions';
import { ThankYouPage } from './views/ThankYouPage';
import { ThankYouPaidPage } from './views/ThankYouPaidPage ';
import { TrainingPage } from './views/TrainingPage';
import { TransactionStatusPage } from './views/transactionStatusPage';
import { UserPage } from './views/userPage';
export const router = createBrowserRouter([
    {
        path: '',
        element: (_jsxs(_Fragment, { children: [_jsx(Navbar, {}), _jsx(loginModal.Modal, {}), _jsx(paymentModal.Modal, {}), _jsx(Outlet, {})] })),
        children: [
            {
                path: BijlesRoutes.HOME_PAGE,
                element: _jsx(Homepage2, {}),
            },
            {
                path: BijlesRoutes.COURSE_PAGE,
                element: _jsx(CoursePage, {}),
                loader: bijlesCoursePageInfoloader(queryClient),
            },
            {
                path: BijlesRoutes.ORDERS_PAGE,
                element: _jsx(OrderPage, {}),
            },
            {
                path: BijlesRoutes.TRAINING_PAGE,
                element: _jsx(TrainingPage, {}),
            },
            {
                path: BijlesRoutes.TRAINING_ARCHIVED_PAGE,
                element: _jsx(TrainingPage, { showArchived: true }),
            },
            {
                path: BijlesRoutes.LOCATION_PAGE,
                element: _jsx(LocationPage, {}),
            },
            {
                path: BijlesRoutes.USER_PAGE,
                element: _jsx(UserPage, {}),
            },
            {
                path: BijlesRoutes.ACCOUNT_PAGE,
                element: _jsx(AccountPage, {}),
            },
            {
                path: BijlesRoutes.ADMIN_OVERVIEW_PAGE,
                element: _jsx(AdminOverViewPage, {}),
            },
            {
                path: BijlesRoutes.LESSON_PAGE,
                element: _jsx(LessonPage, {}),
            },
            {
                path: BijlesRoutes.ATTENDEE_PAGE,
                element: _jsx(AttendeePage, {}),
            },
            {
                path: BijlesRoutes.TAC,
                element: _jsx(TAC, {}),
            },
            {
                path: BijlesRoutes.PS,
                element: _jsx(PrivacyVerklaring, {}),
            },
            {
                path: BijlesRoutes.PAYMENT_FINISHED_PAGE,
                loader: tranasctionStatusloader(queryClient),
                element: _jsx(TransactionStatusPage, {}),
            },
            {
                path: BijlesRoutes.HOMEPAGE2,
                element: _jsx(Homepage, {}),
            },
            {
                path: BijlesRoutes.STUDIES,
                element: _jsx(StudyPage, {}),
            },
            {
                path: BijlesRoutes.THANKS_FOR_ORDERING_PAGE_TRANSACTION,
                element: _jsx(ThankYouPaidPage, {}),
            },
            {
                path: BijlesRoutes.THANKS_FOR_ORDERING_PAGE_FREE,
                element: _jsx(ThankYouPage, {}),
            },
            {
                path: BijlesRoutes.CONTACT,
                element: _jsx(ContactPage, {}),
            },
            // TODO
            // {
            //     path: "*",
            //     element: <404Page />,
            // },
        ],
    },
]);
