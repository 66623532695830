const getCookie = (cookieName) => {
    const cookie = `; ${document.cookie}`.split('; ').find((row) => row.startsWith(`${cookieName}=`));
    if (cookie)
        return cookie.split('=')[1];
    return undefined;
};
export const getCsrfCookieValue = () => {
    const cookieValue = getCookie('csrftoken');
    if (!cookieValue)
        return '';
    return cookieValue;
};
