var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { queryClient } from '../../customQueryclient';
import { customFetch } from '../studentwiki';
export const APIFactory = (baseUrl, baseKey) => ({
    LIST: (filters, options) => {
        const getParamters = filters ? `?${new URLSearchParams(filters).toString()}` : '';
        const queryKey = [`LIST_${baseKey}${getParamters}`];
        return {
            queryKey,
            queryFn: () => __awaiter(void 0, void 0, void 0, function* () { return yield customFetch(`${baseUrl}/${getParamters}`); }),
            invalidate: () => queryClient.invalidateQueries(queryKey),
            select: () => {
                const data = queryClient.getQueryData(queryKey);
                if (options && data) {
                    return data.filter(options.filter);
                }
                return queryClient.getQueryData(queryKey);
            },
        };
    },
    GET: (key) => ({
        queryKey: [`GET_${baseKey}_${key}`],
        queryFn: () => __awaiter(void 0, void 0, void 0, function* () { return yield customFetch(`${baseUrl}/`); }),
    }),
    DELETE: (key) => customFetch(`${baseUrl}/${key}/`, { method: 'DELETE' }),
    POST: (content) => customFetch(`${baseUrl}/`, { method: 'POST', body: JSON.stringify(content) }),
    PUT: (content) => customFetch(`${baseUrl}/${content.id}/`, { method: 'PUT', body: JSON.stringify(content) }),
    // Custom endoint for soft delete models
    RESTORE: (id) => customFetch(`${baseUrl}/${id}/make_active/`, { method: 'POST' }),
});
