import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { createColumnHelper } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { AttendeeAPI } from '../api/bijles/attendeeAPI';
import { BasicTable } from '../libs/Table/BasicTable';
const columnHelper = createColumnHelper();
const attendeeColumns = [
    columnHelper.accessor('transaction.id', { header: 'transaction ID' }),
    columnHelper.accessor('transaction.created_at', { header: 'created at' }),
    columnHelper.accessor('email', {}),
    columnHelper.accessor('transaction.method', { header: 'Payment method' }),
    columnHelper.accessor('transaction.status', { header: 'Payment status' }),
    columnHelper.accessor('transaction.amount', { header: 'amount' }),
    columnHelper.accessor('transaction.mollie_paid_at', { header: 'Paid at' }),
];
export const OrderPage = () => {
    const attendeeList = useQuery(AttendeeAPI.LIST());
    if (!attendeeList.data)
        return _jsx(_Fragment, { children: "Loading..." });
    return (_jsx("div", { children: _jsx("div", { className: " max-w-screen-xl p-4 rounded-lg bg-slate-50 m-auto", children: _jsx(BasicTable, { data: attendeeList.data, columns: attendeeColumns }) }) }));
};
