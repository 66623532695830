import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { generatePath, useNavigate } from 'react-router-dom';
import { BijlesRoutes } from '../App/constants';
import { TrainingAPI } from '../api/bijles/trainingsAPI';
import { queryClient } from '../customQueryclient';
import { ActionButton } from '../libs/BaseComponents/ActionButton';
import { ButtonTriggerModal } from '../libs/BaseComponents/ButtonTriggerModal';
import { InlineModal } from '../libs/Modal/InlineModal';
import { BasicTable } from '../libs/Table/BasicTable';
import { formatMoney } from '../libs/utils';
import { getDateTimeString } from './coursepage';
import { TrainingForm } from './forms/TrainingForm';
const ToggleBetweenActiveAndArchived = ({ showArchived }) => {
    const navigate = useNavigate();
    const onClick = () => {
        if (showArchived)
            navigate(BijlesRoutes.TRAINING_PAGE);
        else
            navigate(BijlesRoutes.TRAINING_ARCHIVED_PAGE);
    };
    const label = showArchived ? 'Show Trainings' : 'Show deleted Training';
    return (_jsx("div", { className: "btn btn-blue inline-block", onClick: onClick, children: label }));
};
export const TrainingPage = ({ showArchived = false }) => {
    const trainingList = useQuery(TrainingAPI.LIST(undefined, { filter: (training) => training.is_deleted === showArchived }));
    const [showModal, setShowModal] = useState(false);
    if (!trainingList.data)
        return _jsx(_Fragment, { children: "Loading..." });
    return (_jsxs("div", { className: "max-w-screen-xl m-auto", children: [_jsxs("h1", { className: "my-5", children: [showArchived ? 'Deleted ' : '', " Trainingen"] }), _jsxs("div", { className: " p-4 rounded-lg bg-slate-50 m-auto", children: [_jsxs("div", { className: "flex gap-5", children: [_jsx("button", { onClick: () => {
                                    setShowModal(true);
                                }, className: "btn btn-blue", children: "Add +" }), _jsx(ToggleBetweenActiveAndArchived, { showArchived: showArchived })] }), showModal && (_jsx(InlineModal, { Content: () => (_jsx(TrainingForm, { type: "CREATE", close: () => {
                                setShowModal(false);
                                TrainingAPI.LIST().invalidate();
                            } })), onClose: () => {
                            setShowModal(false);
                            TrainingAPI.LIST().invalidate();
                        } })), _jsx(BasicTable, { data: trainingList.data, columns: trainingColumns })] })] }));
};
const columnHelper = createColumnHelper();
const getTrainingActionForm = (training, type) => {
    return (close) => _jsx(TrainingForm, { training: training, type: type, close: close });
};
export const DeleteResourceForm = ({ onClick, close, label, AdditionalText = _jsx(_Fragment, {}), }) => {
    return (_jsxs("div", { children: [_jsxs("div", { className: "text-xl", children: ["Are you sure you want to delete this ", label, "?"] }), AdditionalText, _jsxs("div", { className: "flex", children: [_jsx("div", { className: "btn btn-blue whitespace-nowrap mt-1 bg-green-400", onClick: onClick, children: "Yes" }), _jsx("div", { className: "btn btn-blue whitespace-nowrap mt-1 bg-red-400", onClick: () => {
                            close();
                        }, children: "No" })] })] }));
};
const getTrainingDeleteActionForm = (training) => {
    const trainingDelete = useMutation(TrainingAPI.DELETE);
    const additionalDeletionText = (_jsxs("div", { className: " my-5", children: [_jsx("p", { children: "Take the following into account for a deleted Training" }), _jsx("li", { children: "not shown to users" }), _jsx("li", { children: "Attendees and lessons of the training are still visible for you as admin" }), _jsxs("li", { children: ["can not be copied ", 'unless restored'] }), _jsx("li", { children: "can be restored through the deleted training overview" })] }));
    return (close) => {
        const invalidateTraining = () => {
            trainingDelete.mutate(training.id, {
                onSuccess: () => {
                    queryClient.invalidateQueries(TrainingAPI.LIST());
                    close();
                },
            });
        };
        return (_jsx(DeleteResourceForm, { onClick: invalidateTraining, label: "Training", close: close, AdditionalText: additionalDeletionText }));
    };
};
const ButtonTriggerAttendeePage = ({ trainingId }) => {
    const navigate = useNavigate();
    const onClick = () => {
        const path = generatePath(BijlesRoutes.ATTENDEE_PAGE, { trainingId });
        navigate(path);
    };
    return _jsx(ActionButton, { name: "Show Attendees", onClick: onClick });
};
const ButtonTriggerTrainingLessonsPage = ({ trainingId }) => {
    const navigate = useNavigate();
    const onClick = () => {
        const path = generatePath(BijlesRoutes.LESSON_PAGE, { trainingId });
        navigate(path);
    };
    return _jsx(ActionButton, { name: "Show Lessons", onClick: onClick });
};
const RestoreButton = ({ training }) => {
    const restoreTrainingMutation = useMutation(TrainingAPI.RESTORE);
    return (_jsx(ActionButton, { name: "Restore", backgroundColorClass: " bg-blue-500", icon: _jsx(RestoreFromTrashIcon, {}), onClick: () => {
            restoreTrainingMutation.mutate(training.id, {
                onSuccess: () => {
                    TrainingAPI.LIST().invalidate();
                },
            });
            undefined;
        } }));
};
const trainingColumns = [
    columnHelper.accessor('id', {}),
    columnHelper.accessor((row) => row.name, {
        id: 'name',
        header: 'Name',
    }),
    columnHelper.accessor('description', {
        cell: (info) => `${info.getValue().slice(0, 250)}}...`,
    }),
    columnHelper.accessor('course', {}),
    columnHelper.accessor('seats', {
        cell: (info) => `${info.row.original.derived_data.attendee_count}/${info.getValue()}`,
    }),
    columnHelper.accessor('derived_data', {
        id: 'start_date',
        header: 'Start date',
        cell: (info) => {
            const lessons = info.getValue().lessons;
            if (lessons.length === 0)
                return 't.b.d.';
            const firstLesson = lessons[0];
            if (firstLesson.datetimeStart === undefined)
                return 't.b.d.';
            const startDateTime = getDateTimeString(firstLesson.datetimeStart);
            return startDateTime;
        },
    }),
    columnHelper.accessor('derived_data', {
        id: 'end_date',
        header: 'End date',
        cell: (info) => {
            const lessons = info.getValue().lessons;
            if (lessons.length === 0)
                return 't.b.d.';
            const finalLesson = lessons[lessons.length - 1];
            if (finalLesson.datetimeEnd === undefined)
                return 't.b.d.';
            const endDateTime = getDateTimeString(finalLesson.datetimeEnd);
            return endDateTime;
        },
    }),
    columnHelper.accessor('price', {
        cell: (info) => formatMoney(info.getValue()),
    }),
    columnHelper.accessor(() => '', {
        header: 'Email actions',
        cell: (info) => {
            if (info.row.original.is_deleted)
                return _jsx(_Fragment, {});
            _jsx(ActionButton, { name: "Reminder", icon: _jsx(MailOutlineIcon, {}) });
        },
    }),
    columnHelper.accessor(() => '', {
        header: 'Related',
        cell: (info) => (_jsxs(_Fragment, { children: [_jsx(ButtonTriggerAttendeePage, { trainingId: String(info.row.original.id) }), _jsx(ButtonTriggerTrainingLessonsPage, { trainingId: String(info.row.original.id) })] })),
    }),
    columnHelper.accessor(() => '', {
        header: 'Edit',
        cell: (info) => (_jsxs(_Fragment, { children: [_jsx(ButtonTriggerModal, { label: "Edit", getModal: getTrainingActionForm(info.row.original, 'EDIT'), icon: _jsx(EditIcon, {}), afterClose: () => TrainingAPI.LIST().invalidate() }), info.row.original.is_deleted ? (_jsx(_Fragment, { children: " " })) : (_jsx(ButtonTriggerModal, { label: "Copy", getModal: getTrainingActionForm(info.row.original, 'CREATE'), icon: _jsx(ContentCopyIcon, {}), afterClose: () => TrainingAPI.LIST().invalidate() })), info.row.original.is_deleted ? (_jsx(RestoreButton, { training: info.row.original })) : (_jsx(ButtonTriggerModal, { label: "Delete", getModal: getTrainingDeleteActionForm(info.row.original), icon: _jsx(DeleteForeverIcon, {}), afterClose: () => TrainingAPI.LIST().invalidate() }))] })),
    }),
];
