import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { router } from './Router';
import { useUserStore } from './authentication/atoms/userAtom';
import { GOOGLE_CLIENT_ID } from './authentication/endpointsConstants';
import { queryClient } from './customQueryclient';
export const setBodyBackgroundColorToPrimary = () => {
    document.body.style.backgroundColor = 'var(--background-color)';
};
export const setBodyBackgroundColorToPrimarySoft = () => {
    document.body.style.backgroundColor = 'var(--background-color)';
};
// If user switched accounts, logged out, changed name we want it to be reflected in the other
// browser tabs as well, as we don't retrieve user details with every request. Hence when clicking
// on another tab we want the user details to synch with the most recent user details
const windowFocusEffect = () => {
    const loadUserFromLocalStorage = () => {
        const savedUserDetails = localStorage.getItem('user_details');
        if (savedUserDetails != null) {
            useUserStore.getState().setUser(JSON.parse(savedUserDetails));
        }
    };
    window.addEventListener('focus', loadUserFromLocalStorage);
    return () => window.removeEventListener('focus', loadUserFromLocalStorage);
};
ReactGA.initialize('G-470DGZLFBQ');
function App() {
    useEffect(() => {
        const windowFocusCleanup = windowFocusEffect();
        return windowFocusCleanup;
    });
    return (_jsx("div", { className: "m-auto", children: _jsxs(GoogleOAuthProvider, { clientId: GOOGLE_CLIENT_ID, children: [_jsxs(QueryClientProvider, { client: queryClient, children: [_jsx(RouterProvider, { router: router }), _jsx("div", { id: "modalPortal", className: "z-50 absolute" }), _jsx(ReactQueryDevtools, { initialIsOpen: false })] }), _jsx(ToastContainer, { position: "top-center", autoClose: 4000 })] }) }));
}
export default App;
