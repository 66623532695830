import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { InstitutionAPI } from '../../api/bijles/institutionAPI';
import { StudyAPI } from '../../api/bijles/studyAPI';
import { toOptions } from '../../api/bijles/trainingsAPI';
import { queryClient } from '../../customQueryclient';
import { Form } from '../../libs/Forms/Form';
import { Input } from '../../libs/Forms/fields/Input';
import { Select } from '../../libs/Forms/fields/Select';
const schema = z.object({
    name: z.string().min(1).max(200),
    institution: z.coerce.number(),
});
export const StudyForm = ({ study, type, close }) => {
    const editStudy = useMutation(StudyAPI.PUT);
    const addStudy = useMutation(StudyAPI.POST);
    const listInstitutions = useQuery(InstitutionAPI.LIST());
    const typeToName = {
        CREATE: 'Create',
        EDIT: 'Edit',
    };
    const InstitutionOptions = toOptions(listInstitutions.data, (institution) => `${institution.name}`, (institution) => institution.id);
    const onSubmit = (values) => {
        if (type === 'CREATE') {
            addStudy.mutate(values, {
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: StudyAPI.LIST().queryKey });
                    close();
                },
            });
            toast.success('Created a new Study');
        }
        if (type === 'EDIT') {
            if ((study === null || study === void 0 ? void 0 : study.id) === undefined)
                throw Error('On Edit Study should have ID');
            editStudy.mutate(Object.assign(Object.assign({}, values), { id: study.id }), {
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: StudyAPI.LIST().queryKey });
                    close();
                },
            });
            toast.success('Updated the Study');
        }
    };
    return (_jsx(_Fragment, { children: _jsx(Form, { onSubmit: onSubmit, schema: schema, defaultValues: study, children: _jsxs("div", { className: "md:max-w-xl", children: [_jsxs("h1", { children: [typeToName[type], " Study"] }), _jsx(Input, { name: "name" }), _jsx(Select, { name: "institution", options: InstitutionOptions, selectedValue: study === null || study === void 0 ? void 0 : study.institution }), _jsx("div", { children: _jsx("input", { type: "submit", className: "btn btn-blue inline-block mt-3 border-0" }) })] }) }) }));
};
