import { APIFactory } from './APIFactory';
export const TrainingAPI = APIFactory('/studies/api/training', 'bijles_trainings');
export const toOptions = (items, labelSelector, valueSelector) => {
    if (items === undefined)
        return [];
    return items.map((item) => {
        return {
            label: labelSelector(item),
            value: valueSelector(item),
        };
    });
};
