import { QueryClient } from 'react-query';
import { retryStatusCodes } from './fetching/errorStatusCodes';
const shouldRetry = (failureCount, error) => {
    const MAX_RETRIES = 1;
    const status = error === null || error === void 0 ? void 0 : error.status;
    const mayRetry = failureCount <= MAX_RETRIES;
    if (status && mayRetry)
        return status in Object.values(retryStatusCodes);
    return false;
};
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
            refetchOnWindowFocus: false,
            retry: shouldRetry,
        },
    },
});
