import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { modalFactory } from './base';
const SubscriptionEntry = ({ url, priceMonthly, pricePeriod, period, }) => {
    const { t } = useTranslation();
    const HandleSubscriptionPayment = () => {
        window.location.replace(url);
    };
    // eslint-disable-next-line @typescript-eslint/ban-types
    const FeatureEntry = ({ Icon, text }) => {
        return (_jsxs("div", { className: "mt-2", children: [_jsx(Icon, { className: "align-middle" }), _jsx("span", { className: "align-middle", children: text })] }));
    };
    return (_jsxs("div", { className: "hover:shadow-md rounded-lg inline-block p-3 m-3 hover:bg-secondary-light", children: [_jsxs("h5", { className: "card-title text-muted text-uppercase text-center", children: [period, " invoiced"] }), _jsx("div", { className: "d-flex my-4 text-center", children: _jsxs("div", { className: "m-auto d-flex", children: [_jsx("span", { className: "text-base font-light", children: "EUR" }), _jsxs("span", { className: "text-6xl leading-none font-light", children: [priceMonthly, "*"] }), _jsx("span", { className: "text-base font-light leading-6", children: "/month" })] }) }), _jsxs("p", { className: "text-center", children: ["*Invoiced ", _jsx("b", { children: period }), " \u20AC ", pricePeriod, ", can be canceled any time."] }), _jsxs("div", { className: "px-3", children: [_jsx(FeatureEntry, { Icon: CheckIcon, text: t('feature-follow-courses') }), _jsx(FeatureEntry, { Icon: CheckIcon, text: t('feature-edit-document') }), _jsx(FeatureEntry, { Icon: CheckIcon, text: t('feature-access-some-documents-and-flashcards') }), _jsx(FeatureEntry, { Icon: AddIcon, text: t('feature-access-all-documents') }), _jsx(FeatureEntry, { Icon: AddIcon, text: t('feature-access-all-flashcards') }), _jsx(FeatureEntry, { Icon: AddIcon, text: t('feature-early-access-to-new-features') }), _jsx("div", { className: "d-flex justify-content-center m-4", children: _jsx("button", { className: "btn btn-blue", onClick: HandleSubscriptionPayment, children: "Yes, I can miss a beer a month to graduate" }) })] })] }));
};
const ModalContent = () => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx("h1", { className: "text-primary", children: t('modal-buy-subscription-text') }), _jsx(SubscriptionEntry, { url: "/payments/subscription/quarterly/", priceMonthly: "4.95", pricePeriod: "14.85", period: "quarterly" }), _jsx(SubscriptionEntry, { url: "/payments/subscription/yearly/", priceMonthly: "3.95", pricePeriod: "47.40", period: "yearly" })] }));
};
const modalRef = React.createRef();
export const paymentModal = modalFactory(ModalContent, modalRef, 'modal-stretch');
