import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from 'react-query';
import { useTypedParams } from '../App/Helpers';
import { transactionStatusQuery } from '../api/transactionLoader';
import { EXAM_CELEBRATION_IMAGE } from './ThankYouPage';
import { PageHeader } from './homepage2';
import { Footer } from './subpages/Footer';
export const LoadingSpinner = () => {
    return (_jsxs("div", { className: "lds-ring", children: [_jsx("div", {}), _jsx("div", {}), _jsx("div", {}), _jsx("div", {})] }));
};
const TransactionPaidPage = () => {
    return (_jsxs(_Fragment, { children: [_jsx("h1", { children: "Thank you for ordering with TuTutor!" }), _jsxs("h3", { children: ['Let\'s', " ace that exam!"] }), _jsx("div", { className: "my-2", children: 'In a few minutes you\'ll receive a confirmation email with more information about the training!' })] }));
};
const TransactionFailedPage = () => {
    return (_jsxs(_Fragment, { children: [_jsxs("h1", { children: ["Your payment failed ", ':('] }), _jsx("div", { className: "my-2", children: 'That\'s unfortunate, this can have a variety of reasons, please check with your bank! If the payments keep failing please contact info@tututor.nl' })] }));
};
const TransactionCanceledPage = () => {
    return (_jsxs(_Fragment, { children: [_jsx("h1", { children: "Cancled payment!" }), _jsx("h3", { children: 'That\'s unfortunate!' }), _jsx("div", { className: "my-2", children: 'You\'re always free to make a reservation again! Maybe we\'ll see you in one of our other exam trainings!' })] }));
};
const TransactionPendingPage = () => {
    return (_jsxs(_Fragment, { children: [_jsx("h1", { children: "Your order is still pending at your bank!" }), _jsx("h3", { children: "Sometimes order are not directly processed by their bank, it should not take long though!" }), _jsx("div", { className: "my-2", children: 'When the payment is succesful we\'ll send you and email, if you won\'t receive an email within 15 min, please contact us at info@tututor.nl!' })] }));
};
const TransactionExpiredPage = () => {
    return (_jsxs(_Fragment, { children: [_jsx("h1", { children: "You order had expired" }), _jsx("h3", { children: "The payment was not done withing 15 min after creating the order, and therefore it expired" })] }));
};
const TransactionOpenPage = () => {
    return (_jsxs(_Fragment, { children: [_jsx("h1", { children: "Your order is not payed yet" }), _jsx("h3", { children: "The payment status open, indicating no bank transaction is set up yet" }), _jsx("div", { children: "It can be that you still have the payment page open in another tab, or you closed the payment page without making a payment." })] }));
};
export const ThankYouPaidPage = () => {
    var _a, _b;
    const { transactionId } = useTypedParams();
    const transactionStatus = useQuery(transactionStatusQuery(transactionId));
    const statusToPage = {
        paid: _jsx(TransactionPaidPage, {}),
        failed: _jsx(TransactionFailedPage, {}),
        canceled: _jsx(TransactionCanceledPage, {}),
        open: _jsx(TransactionOpenPage, {}),
        pending: _jsx(TransactionPendingPage, {}),
        expired: _jsx(TransactionExpiredPage, {}),
    };
    return (_jsxs(_Fragment, { children: [_jsx(PageHeader, {}), _jsx("div", { className: "max-w-screen-xl m-auto", children: _jsx("div", { className: "p-14", children: _jsxs("div", { className: "grid grid-cols-2 gap-5", children: [_jsxs("div", { className: "relative min-h-[330px]", children: [transactionStatus.isLoading && _jsx(LoadingSpinner, {}), transactionStatus.isSuccess &&
                                        ((_a = transactionStatus.data) === null || _a === void 0 ? void 0 : _a.status) &&
                                        statusToPage[(_b = transactionStatus.data) === null || _b === void 0 ? void 0 : _b.status], _jsxs("div", { className: " absolute bottom-0", children: ["For any questions, please feel free to contact ", _jsx("b", { children: "info@tututor.nl" })] })] }), _jsx("div", { className: "bg-cover", style: {
                                    backgroundImage: `url(${EXAM_CELEBRATION_IMAGE})`,
                                } })] }) }) }), _jsx(Footer, {})] }));
};
