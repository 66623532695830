import { useParams } from 'react-router-dom';
// "route" parameter isn't used, but it's needed to infer the type of the params, this
// could also be done by passing the route as a generic to the function, but this is
// easier to use.
export const useTypedParamsHelper = (route) => {
    return useParams();
};
//////////////////////////////////////////////
// Example
//////////////////////////////////////////////
// const routes = {
//     PAGE_WITHOUT_VARIABLE: '/home',
//     PAGE_WITH_VARIABLE: '/page/:variable/',
//     PAGE_WITH_OTHER_VARIABLE: '/other_page/:variable2/',
// } as const;
// export const useCustomTypedParams = <TPath extends string | undefined>(route?: TPath) => {
//     return useTypedParams<typeof routes, TPath>(route);
// };
// // Testing types
// const TestTypeComponent = () => {
//     // TypeChecking Should error on 'neverValid'
//     const { variable, variable2, neverValid } = useCustomTypedParams();
// };
// const TestTypeComponent2 = () => {
//     // TypeChecking Should error on 'variable2' and 'neverValid'
//     const { variable, variable2, neverValid } = useCustomTypedParams(routes.PAGE_WITH_VARIABLE);
// };
// const TestTypeComponent3 = () => {
//     // TypeChecking Should error on 'variable1' and 'neverValid'
//     const { variable, variable2, neverValid } = useCustomTypedParams(routes.PAGE_WITH_OTHER_VARIABLE);
// };
// const TestTypeComponent4 = () => {
//     // TypeChecking Should error on everything
//     const { variable, variable2, neverValid } = useCustomTypedParams(routes.PAGE_WITHOUT_VARIABLE);
// };
