import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { createColumnHelper } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useTypedParams } from '../App/Helpers';
import { BijlesRoutes } from '../App/constants';
import { AttendeeAPI } from '../api/bijles/attendeeAPI';
import { BasicTable } from '../libs/Table/BasicTable';
const columnHelper = createColumnHelper();
const attendeeColumns = [
    columnHelper.accessor('id', {}),
    columnHelper.accessor('first_name', {}),
    columnHelper.accessor('last_name', {}),
    columnHelper.accessor('email', {}),
    columnHelper.accessor('transaction', {
        id: 'method',
        header: 'Payment method',
        cell: (info) => {
            var _a;
            return _jsx("div", { children: (_a = info.cell.getValue()) === null || _a === void 0 ? void 0 : _a.method });
        },
    }),
    columnHelper.accessor('transaction', {
        id: 'status',
        header: 'Payment status',
        cell: (info) => {
            var _a;
            return _jsx("div", { children: (_a = info.cell.getValue()) === null || _a === void 0 ? void 0 : _a.status });
        },
    }),
    columnHelper.accessor('transaction', {
        id: 'amount',
        header: 'Payment amount',
        cell: (info) => {
            var _a;
            return _jsx("div", { children: (_a = info.cell.getValue()) === null || _a === void 0 ? void 0 : _a.amount });
        },
    }),
    columnHelper.accessor('transaction', {
        id: 'paidAt',
        header: 'Paid at',
        cell: (info) => {
            var _a;
            return _jsx("div", { children: (_a = info.cell.getValue()) === null || _a === void 0 ? void 0 : _a.mollie_paid_at });
        },
    }),
];
export const AttendeePage = () => {
    const { trainingId } = useTypedParams(BijlesRoutes.ATTENDEE_PAGE);
    const attendeeList = useQuery(Object.assign(Object.assign({}, AttendeeAPI.LIST({ training: trainingId })), { enabled: trainingId !== undefined }));
    if (!attendeeList.data)
        return _jsx(_Fragment, { children: "Loading..." });
    return (_jsx("div", { children: _jsx("div", { className: " max-w-screen-xl p-4 rounded-lg bg-slate-50 m-auto", children: _jsx(BasicTable, { data: attendeeList.data, columns: attendeeColumns }) }) }));
};
