import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoginButtonGoogle } from '../../authentication/google';
import { modalFactory } from './base';
const loginModalRef = React.createRef();
const ModalContent = () => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx("h1", { className: "logo text-4xl mb-14", children: t('company-name') }), _jsx("div", { className: "my-3", children: _jsx(LoginButtonGoogle, { onSuccess: loginModal.hide }) }), _jsxs("p", { className: " text-gray-500 text-sm pt-8", children: [t('login-accept-terms-and-condition-text'), _jsx("a", { href: "/static/Algemene voorwaarden StudentWiki.docx", target: "_blank", children: t('login-terms-and-condition-ling-text') })] }), _jsx("div", { className: "pt-8 cursor-pointer", onClick: loginModal.hide, children: "Close" })] }));
};
export const loginModal = modalFactory(ModalContent, loginModalRef, 'modal-sm');
