import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
export const BasicTable = ({ data, columns }) => {
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });
    return (_jsxs("table", { className: "w-full text-left", children: [_jsx("thead", { children: table.getHeaderGroups().map((headerGroup) => (_jsx("tr", { id: 'headerGroup.id:' + headerGroup.id, children: headerGroup.headers.map((header) => (_jsx("th", { id: 'header.id:' + header.id, children: header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext()) }, header.id))) }, headerGroup.id))) }), _jsx("tbody", { className: "[&>*:nth-child(odd)]:bg-gray-100", children: table.getRowModel().rows.map((row) => (_jsx("tr", { id: 'row.id: ' + row.id, children: row.getVisibleCells().map((cell) => (_jsx("td", { children: flexRender(cell.column.columnDef.cell, cell.getContext()) }, cell.id))) }, row.id))) })] }));
};
