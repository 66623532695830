import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { setBodyBackgroundColorToPrimarySoft } from '../App';
import { AccountOverview, AccountOverviewButton } from './subpages/AccountOverview';
import { SubscriptionOverview, SubscriptionOverviewButton } from './subpages/SubscriptionOverview';
const Content = ({ tab }) => {
    const TabContent = () => {
        if (tab === 'subscription')
            return _jsx(SubscriptionOverview, {});
        return _jsx(AccountOverview, {});
    };
    return (_jsx("div", { className: "document", children: _jsx(TabContent, {}) }));
};
export const AccountPage = () => {
    setBodyBackgroundColorToPrimarySoft();
    const [tab, setTab] = useState('account');
    return (_jsxs("div", { className: "mx-4 grid grid-cols-20-auto xl:grid-cols-20-auto-20", children: [_jsx("div", { className: "max-w-xs", children: _jsxs("div", { className: "top-14 left-0 bottom-0 sticky overflow-y-scroll max-h-below-nav scrollbar-light", children: [_jsx(AccountOverviewButton, { setTab: setTab, tab: tab }), _jsx(SubscriptionOverviewButton, { setTab: setTab, tab: tab })] }) }), _jsx("div", { children: _jsx("div", { className: "max-w-screen-md m-auto", children: _jsx(Content, { tab: tab }) }) }), _jsx("div", { className: "max-w-xs", children: _jsx("div", { className: "right-0 sticky overflow-y-scroll max-h-below-nav scrollbar-light top-24" }) })] }));
};
