var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useQuery } from 'react-query';
import { useTypedParams } from '../App/Helpers';
import { customFetch } from './studentwiki';
export const useTransactionStatusResult = () => {
    const { transactionId } = useTypedParams();
    const transactionStatus = useQuery(transactionStatusQuery(transactionId));
    return transactionStatus;
};
export const transactionStatusQuery = (transactionId) => {
    if (transactionId === undefined)
        throw Error('No TransactionId provided');
    return {
        queryKey: ['transaction', 'transaction' + transactionId],
        queryFn: () => __awaiter(void 0, void 0, void 0, function* () {
            return yield customFetch(`/payments/payed/${transactionId}/`);
        }),
    };
};
export const tranasctionStatusloader = (queryClient) => ({ params: { transactionId } }) => __awaiter(void 0, void 0, void 0, function* () {
    const transactionStatusDetailsQuery = transactionStatusQuery(transactionId);
    const transactionStatusDetails = yield queryClient.fetchQuery(transactionStatusDetailsQuery);
    return transactionStatusDetails;
});
