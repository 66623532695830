import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import axios from 'axios';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { RoomAPI } from '../../api/bijles/roomAPI';
import { getCsrfCookieValue } from '../../api/getCsrfFromCookie';
import { queryClient } from '../../customQueryclient';
import { Form } from '../../libs/Forms/Form';
import { Input } from '../../libs/Forms/fields/Input';
const schema = z.object({
    meetingLink: z.string().min(1).max(2000),
});
export const MeetingLinkForm = ({ lesson, close }) => {
    const mailMeetingLinkToAttendees = useMutation((meetingLink) => axios.post('/studies/api/lesson/send_meeting_link/', { lessonId: lesson.id, meetingLink }, { headers: { 'Content-Type': 'application/json', 'X-CSRFToken': getCsrfCookieValue() } }));
    const onSubmit = (values) => {
        mailMeetingLinkToAttendees.mutate(values, {
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: RoomAPI.LIST().queryKey });
                toast.error('This is work is progres, mailing is not really send!!');
                close();
            },
            onError: () => {
                toast.error('Something went wrong with the mailing, please contact admin');
            },
        });
    };
    return (_jsx(_Fragment, { children: _jsx(Form, { onSubmit: onSubmit, schema: schema, children: _jsxs("div", { className: "md:max-w-xl", children: [_jsx("h1", { children: "Send this meeting link to attendees" }), _jsx(Input, { name: "meetingLink" }), _jsx("div", { children: _jsx("input", { type: "submit", className: "btn btn-blue inline-block mt-3 border-0" }) })] }) }) }));
};
