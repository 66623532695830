import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { createColumnHelper } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useTypedParams } from '../../App/Helpers';
import { BijlesRoutes } from '../../App/constants';
import { LessonAPI } from '../../api/bijles/lessonAPI';
import { ButtonTriggerModal } from '../../libs/BaseComponents/ButtonTriggerModal';
import { BasicTable } from '../../libs/Table/BasicTable';
import { LoadingSpinner } from '../ThankYouPaidPage ';
import { getDateTimeString } from '../coursepage';
import { MeetingLinkForm } from '../forms/MeetingLinkForm';
import { PageHeader } from '../homepage2';
const getMailMeetingInformationActionForm = (lesson) => {
    return (close) => (_jsx("div", { children: _jsx(MeetingLinkForm, { lesson: lesson, close: close }) }));
};
const columnHelper = createColumnHelper();
const attendeeColumns = [
    columnHelper.accessor('id', {}),
    columnHelper.accessor('name', {}),
    columnHelper.accessor('datetimeStart', {
        cell: (info) => getDateTimeString(info.getValue())
    }),
    columnHelper.accessor('datetimeEnd', {
        cell: (info) => getDateTimeString(info.getValue())
    }),
    columnHelper.accessor('training', {}),
    columnHelper.accessor('room', {}),
    columnHelper.accessor(() => '', {
        header: 'Email actions',
        cell: (info) => (_jsx(_Fragment, { children: _jsx(ButtonTriggerModal, { label: "Send meeting link", getModal: getMailMeetingInformationActionForm(info.row.original), icon: _jsx(MailOutlineIcon, {}), afterClose: () => undefined }) })),
    }),
];
export const LessonPage = () => {
    const { trainingId } = useTypedParams(BijlesRoutes.LESSON_PAGE);
    const lessonList = useQuery(Object.assign(Object.assign({}, LessonAPI.LIST({ training: trainingId })), { enabled: trainingId !== undefined }));
    if (!lessonList.data)
        return _jsx(LoadingSpinner, {});
    return (_jsxs("div", { children: [_jsx(PageHeader, {}), _jsxs("div", { className: " max-w-screen-xl p-4 rounded-lg bg-slate-50 m-auto my-5", children: [_jsxs("h1", { children: ["Lessons for training: ", trainingId] }), _jsx(BasicTable, { data: lessonList.data, columns: attendeeColumns })] })] }));
};
