import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
const showModal = (ref) => {
    if (ref.current)
        ref.current.style.display = 'block';
};
export const hideModal = (ref) => {
    if (ref.current)
        ref.current.style.display = 'none';
};
const hideElementOnClick = (element, elementToClose) => {
    const refIsSet = !!element.current;
    if (refIsSet)
        return (element.current.onclick = (e) => {
            const clickedOutsideModal = element.current === e.target;
            if (clickedOutsideModal)
                hideModal(elementToClose);
        });
};
const Modal = ({ Content, modalRef, modalSize = 'modal', }) => {
    const layerBehindModalRef = useRef(null);
    useEffect(() => {
        hideElementOnClick(layerBehindModalRef, modalRef);
    }, [layerBehindModalRef, modalRef]);
    return (_jsx("div", { className: "hidden", ref: modalRef, children: _jsx("div", { className: "layerBehindModal", ref: layerBehindModalRef, children: _jsx("div", { className: modalSize, children: _jsx(Content, {}) }) }) }));
};
export const modalFactory = (Content, modalRef, modalSize) => {
    return {
        show: () => showModal(modalRef),
        hide: () => hideModal(modalRef),
        ref: modalRef,
        Modal: () => _jsx(Modal, { modalRef: modalRef, Content: Content, modalSize: modalSize }),
    };
};
