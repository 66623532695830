import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BijlesRoutes } from '../App/constants';
import { useLogoutMutation } from '../api/mutations';
import flagGB from '../assets/flags/flagGB.svg';
import flagNL from '../assets/flags/flagNL.svg';
import { useUserStore } from '../authentication/atoms/userAtom';
import { i18n } from '../config/i18n/i18init';
import { loginModal } from './modals/LoginModal';
export const LogoText = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const goHome = () => {
        navigate('/');
    };
    return (_jsx("h4", { className: "logo cursor-pointer text-primary", onClick: goHome, children: t('company-name') }));
};
export const BtnTriggerLanguage = () => {
    const onClickGB = () => {
        i18n.changeLanguage('en');
    };
    const onClickNL = () => {
        i18n.changeLanguage('nl');
    };
    return (_jsxs("div", { children: [_jsx("img", { src: flagNL, alt: "Dutch flag", className: "h-4 mr-3 cursor-pointer", onClick: onClickNL }), _jsx("img", { src: flagGB, alt: "Great Britain flag", className: "h-4 mr-3 cursor-pointer", onClick: onClickGB })] }));
};
const UserName = () => {
    const user = useUserStore((state) => state.user);
    if (!user)
        return _jsx(_Fragment, {});
    return (_jsxs("span", { className: "text-secondary font-bold px-3 float-right", children: [user.first_name, " ", user.last_name] }));
};
const TriggerLoginModalButton = () => {
    return (_jsx("button", { className: "btn btn-blue float-right", onClick: loginModal.show, children: "Login" }));
};
const OverviewDropDown = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const logoutMutation = useLogoutMutation();
    const dropDownEntryCSS = 'block no-underline p-3 rounded-md cursor-pointer hover:bg-secondary-light';
    const goToHomePage = () => navigate(BijlesRoutes.ACCOUNT_PAGE);
    const goToUserPage = () => navigate(BijlesRoutes.USER_PAGE);
    const triggerlogout = () => logoutMutation.mutate();
    return (_jsxs("div", { className: "dropdown float-right", children: [_jsx("button", { className: "btn btn-blue", children: t('overview') }), _jsxs("div", { className: "dropdown-content right-0 shadow-lg", children: [_jsx("span", { className: dropDownEntryCSS, onClick: goToHomePage, children: "Account" }), _jsx("span", { className: dropDownEntryCSS, onClick: goToUserPage, children: "Overview" }), _jsx("span", { className: dropDownEntryCSS, onClick: triggerlogout, children: "Logout" })] })] }));
};
const Navbar = () => {
    const loggedIn = useUserStore((state) => state.loggedIn);
    return (_jsxs("div", { className: "p-3 sticky top-0 bg-opacity-50 bg-primary flex items-center justify-between m-4 rounded-lg z-10 hidden", children: [_jsx(LogoText, {}), _jsxs("div", { className: "flex items-center", children: [_jsx(BtnTriggerLanguage, {}), _jsx(UserName, {}), loggedIn ? _jsx(OverviewDropDown, {}) : _jsx(TriggerLoginModalButton, {})] })] }));
};
export default Navbar;
