import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PlaceIcon from '@mui/icons-material/Place';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { generatePath, useNavigate } from 'react-router-dom';
import { setBodyBackgroundColorToPrimarySoft } from '../App';
import { useTypedParams } from '../App/Helpers';
import { BijlesRoutes } from '../App/constants';
import { PublicTrainingAPI } from '../api/bijles/publicTrainingAPI';
import { InlineModal } from '../libs/Modal/InlineModal';
import { formatMoney } from '../libs/utils';
import { AttendeeForm } from './forms/AttendeeForm';
import { AdditionalLinksNavBar, SelectCourse2 } from './homepage2';
import { BtnTriggerLanguage, LogoText } from './navbar';
import { Footer } from './subpages/Footer';
export function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        }
        else {
            collection.push(item);
        }
    });
    return map;
}
const TEACHTER_IMG_URL = '/static/hicham_profile.jpeg';
const TrainingEntry = ({ training }) => {
    const [showModal, setShowModal] = useState(false);
    const { t } = useTranslation();
    const isFreeTraining = training.price === 0;
    const hasLessons = training.derived_data.lessons.length > 0;
    return (_jsxs("div", { className: "grid grid-cols-auto gap-4 mt-6", children: [_jsxs("div", { children: [_jsx("img", { className: "rounded-full w-24 h-24 mt-9", src: TEACHTER_IMG_URL }), _jsxs("p", { className: "text-sm text-center", children: [training.derived_data.trainer.first_name, " ", training.derived_data.trainer.last_name] })] }), _jsxs("div", { children: [_jsx("h1", { children: training.name }), _jsx("div", { className: "mt-1", style: { whiteSpace: 'pre-wrap' }, children: training.description }), hasLessons && (_jsx("div", { className: "grid grid-cols-1 md:grid-cols-2 gap-4 mt-5", children: training.derived_data.lessons &&
                            training.derived_data.lessons.map((lesson) => (_jsx(LessonCard, { lesson: lesson }, lesson.id))) })), _jsxs("div", { className: "flex flex-col md:flex-row md:items-end gap-3 mt-5", children: [_jsx("div", { onClick: () => setShowModal(true), className: "btn btn-blue", children: isFreeTraining ? t('order-free-training') : t('order-training') }), !isFreeTraining && (_jsxs(_Fragment, { children: [_jsxs("span", { children: [t('total'), ":"] }), _jsx("span", { className: "font-black text-4xl", children: formatMoney(training.price) })] }))] }), _jsx("div", { className: " text-secondary my-5", children: t('limited-places-available') })] }), showModal && (_jsx(InlineModal, { Content: () => _jsx(AttendeeForm, { close: () => setShowModal(false), training: training }), onClose: () => setShowModal(false) }))] }));
};
export const getDateTimeString = (ISODateTimetring) => {
    return `${getTimeString(ISODateTimetring)} - ${ISOTimeToNLDateString(ISODateTimetring)}`;
};
export const getTimeString = (ISODateTimeString) => {
    const datetime = new Date(ISODateTimeString);
    return datetime.toLocaleTimeString('nl-NL', {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'Europe/Amsterdam',
    });
};
export const ISOTimeToNLDateString = (isoDateString) => {
    return new Date(isoDateString).toLocaleDateString('nl-NL', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        timeZone: 'Europe/Amsterdam',
    });
};
const LessonCard = ({ lesson }) => {
    const dateString = ISOTimeToNLDateString(lesson.datetimeStart);
    return (_jsxs("div", { className: " bg-primary rounded-md text-white p-5 flex flex-col gap-4", children: [_jsx("div", { children: _jsx("b", { children: lesson.name }) }), _jsxs("div", { className: "flex align-baseline gap-2", children: [_jsx(DateRangeIcon, {}), dateString] }), _jsxs("div", { className: "flex align-baseline gap-2", children: [_jsx(AccessTimeIcon, {}), getTimeString(lesson.datetimeStart), " - ", getTimeString(lesson.datetimeEnd)] }), _jsx("div", { className: "flex align-baseline gap-2", children: _jsx(PlaceIcon, {}) })] }));
};
export const PageHeader = ({ children }) => {
    return (_jsx("div", { className: "relative bg-primary-light", children: _jsxs("div", { className: "max-w-screen-lg m-auto", children: [_jsxs("div", { className: "flex justify-between py-6", children: [_jsxs("div", { className: " relative flex gap-5 items-baseline", children: [_jsx(LogoText, {}), _jsx(AdditionalLinksNavBar, {})] }), _jsx("div", { className: "relative flex gap-5", children: _jsx(BtnTriggerLanguage, {}) })] }), children] }) }));
};
export const CoursePage = () => {
    setBodyBackgroundColorToPrimarySoft();
    const navigate = useNavigate();
    const { courseId } = useTypedParams();
    if (!courseId)
        throw Error('Loader should receive courseId');
    const trainingList = useQuery(PublicTrainingAPI.LIST(undefined, {
        filter: (item) => item.course === parseInt(courseId),
    }));
    if (!trainingList.data) {
        // TODO: fix properly showing a loading state
        return _jsx(_Fragment, { children: "No Data yet..." });
    }
    const navigateToCourse = (courseId) => {
        if (courseId === undefined)
            return;
        const path = generatePath(BijlesRoutes.COURSE_PAGE, { courseId: courseId.toString() });
        navigate(path);
    };
    return (_jsxs(_Fragment, { children: [_jsx(PageHeader, {}), _jsx("div", { className: "bg-sky-100", children: _jsx("div", { className: "relative p-14 object-center max-w-screen-lg m-auto", children: _jsx(SelectCourse2, { calledOnCourseChange: navigateToCourse, courseId: courseId, showGoToButton: false }) }) }), _jsxs("div", { className: "mx-4 grid xl:grid-cols-20-auto-20", children: [_jsx("div", { className: "md:max-w-xs", children: _jsx("div", { className: "top-14 left-0 bottom-0 sticky overflow-y-scroll max-h-below-nav scrollbar-light" }) }), _jsx("div", { children: _jsx("div", { className: "md:max-w-screen-md m-auto", children: trainingList.data.map((training) => (_jsx(TrainingEntry, { training: training }, training.id))) }) }), _jsx("div", { className: "max-w-xs", children: _jsx("div", { className: "right-0 sticky overflow-y-scroll max-h-below-nav scrollbar-light top-14" }) })] }), _jsx(Footer, {})] }));
};
