import { jsx as _jsx } from "react/jsx-runtime";
// This file is inspired by: https://www.thisdot.co/blog/how-to-create-reusable-form-components-with-react-hook-forms-and-typescript
// demo: https://codesandbox.io/s/react-form-hook-zod-ngylel?file=/src/components/ReactForm.tsx
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
export const Form = ({ schema, onSubmit, children, defaultValues, }) => {
    const methods = useForm({
        defaultValues,
        resolver: zodResolver(schema),
    });
    return (_jsx(FormProvider, Object.assign({}, methods, { children: _jsx("form", { onSubmit: methods.handleSubmit(onSubmit), children: children }) })));
};
