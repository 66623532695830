import { create } from 'zustand';
import { combine, persist } from 'zustand/middleware';
const USER_LOCALSTORAGE_KEY = 'user_auth_details';
const getLoggedOutState = () => {
    const loggedOutState = { loggedIn: false, user: undefined };
    return loggedOutState;
};
export const useUserStore = create(persist(combine(getLoggedOutState(), (set) => ({
    setUser: (user) => {
        set(user);
    },
    clear: () => set(getLoggedOutState()),
})), {
    version: 0,
    name: USER_LOCALSTORAGE_KEY, // unique name
}));
