import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { InlineModal } from '../Modal/InlineModal';
export const ButtonTriggerModal = ({ label, getModal, icon, afterClose, }) => {
    const [modal, setModal] = useState(false);
    const closeModal = () => {
        setModal(false);
        afterClose && afterClose();
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex items-center btn btn-blue whitespace-nowrap mt-1 bg-green-400 gap-2", onClick: () => {
                    setModal(true);
                }, children: [icon, label] }), modal && _jsx(InlineModal, { Content: () => getModal(closeModal), onClose: closeModal })] }));
};
